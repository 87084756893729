import { useState, useEffect } from "react";
import localStorageStore from "../../../utils/localStorageStore";
import role from "../../../utils/role";
import Swal from "sweetalert2";
import { fetchProjectEpicsAndSprints, fetchProjectReworkCount } from "../../../services/managerWorkListService";
import SprintDetailsModal from "./EpicSprintManagement/SprintDetailsModal";
import { updateSprintStatus } from "../../../services/managerWorkListService";
// import { deleteSprint } from "../../../services/managerWorkListService";
import moment from "moment";
import { sprintStatusIdConstants } from "../../../utils/constants";
const SprintManagement = ({ worklistId, success, setSuccess, setSelectedSprint }) => {
  const [showMainModalBox, setShowMainModalBox] = useState(false);
  const [modalValues, setModalValues] = useState({ worklist_id: worklistId });
  const [sprints, setSprints] = useState([]);
  const [reworkCount, setReworkCount] = useState();
  const [loadingRework, setLoadingRework] = useState(true);
  const [notStartedSprints, setNotStartedSprints] = useState([]);

  useEffect(() => {
    fetchProjectEpicsAndSprints(worklistId).then((response) => {
      const sprintsData = response?.data?.sprints;
      setSprints(sprintsData);

      const notStartedSprints = response?.data?.sprints?.filter((sprint) => {
        return sprint.SprintStatusId === sprintStatusIdConstants.NOT_STARTED_STATUS_ID;
      })
      setNotStartedSprints(notStartedSprints);

      if (response?.data?.active_sprint) {
        setSelectedSprint(response?.data?.active_sprint?.value);
      } else if (sprintsData && sprintsData.length > 0) {
        setSelectedSprint(sprintsData[0]?.value);
      }
    });
    if(decryptRole === role.Admin) {
      fetchProjectReworkCount(worklistId)
      .then((response) => {
        if (response?.data?.reworkCount) {
          setReworkCount(response?.data?.reworkCount);
        }
      })
      .finally(() => {
        setLoadingRework(false);
      });
    }

    // eslint-disable-next-line
  }, [worklistId, success]);

  const closeTaskModal = () => {
    setShowMainModalBox(false);
  };
  // async function handleShowDeleteTask(id) {
  //   Swal.fire({
  //     text: "Are you sure you would like to delete this Sprint?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     buttonsStyling: false,
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //     customClass: {
  //       confirmButton: "btn btn-danger",
  //       cancelButton: "btn btn-active-light",
  //     },
  //   }).then(async function (result) {
  //     if (result?.value === true) {
  //       deleteSprint(id).then((res) => {
  //         if (res?.success === true) {
  //           window.toastr.success(res?.message);
  //           setSuccess(!success);
  //         } else {
  //           window.toastr.error(res?.message);
  //         }
  //       });
  //     }
  //   });
  // }

  const handleSprintUpdateClick = (e, item) => {
    e.preventDefault();
    setModalValues({
      ...modalValues,
      id: item?.value,
      name: item?.label ?? "",
      description: item?.description ?? "",
      duration: item?.duration ?? "",
      startDate: moment(item?.start_date).format("DD/MM/YYYY"),
      endDate: moment(item?.end_date).format("DD/MM/YYYY"),
      sprint_owner: {
        label: `${item?.owner_fname ?? ""} ${item?.owner_lname ?? ""}`,
        value: item?.owner_id ?? "",
        image: item?.owner_image ?? "",
      },
      modal_type: "update",
    });
    setShowMainModalBox(true);
  };

  const handleSprintAddClick = (e) => {
    e.preventDefault();
    setModalValues({ worklist_id: modalValues.worklist_id, modal_type: "add" });
    setShowMainModalBox(true);
  };

  async function handleSprintStatus(sprint_id, status, unapprovedTasksCount) {
    if (status === "stop" && unapprovedTasksCount > 0 && notStartedSprints.length === 0) {
      Swal.fire({
        text: `There are unapproved tasks in the current sprint. Please either create a new sprint to move these tasks or approve the pending tasks.`,
        icon: "warning",
        showCancelButton: false,
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-danger",
        },
      });
    } else {
      Swal.fire({
        text: `Are you sure you would like to ${status === "start" ? "start" : "stop"} this Sprint? `,
        icon: "warning",
        html:
          status === "stop" && notStartedSprints.length > 0
            ? `
          <div>
          <label for="sprintSelect" style="font-weight: bold;">Select a sprint from the dropdown to move tasks:</label>
          <select id="sprintSelect" class="swal2-input" style="padding: 10px; border-radius: 5px;">
          <option value="" disabled selected>Select a sprint</option>
            ${notStartedSprints.map((sprint) => `<option value="${sprint.value}">${sprint.label}</option>`).join("")}
          </select>
          </div>`
          : "",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: `btn ${status === "start" ? "btn-success" : "btn-danger"} `,
          cancelButton: "btn btn-active-light",
        },
        preConfirm: () => {
          const selectedSprintId = Number(document.getElementById('sprintSelect')?.value);
          if (!selectedSprintId && status === "stop") {
            Swal.showValidationMessage('Please select a sprint to move the tasks.');
            return false;
          }
        }
      }).then(async function (result) {
        if (result?.value === true) {
          const selectedSprintId = Number(document.getElementById('sprintSelect')?.value) || null;
          updateSprintStatus(sprint_id, { status, worklist_id: worklistId, nextSprintId: selectedSprintId}).then((res) => {
            if (res?.success) {
              window.toastr.success(res?.message);
              setSuccess(!success);
            } else {
              window.toastr.error(res);
            }
          });
        }
      });
    }
  }

  const decryptRole = localStorageStore.getRole();
  return (
    <div className="col-xl-7">
      <div className="card card-flush mb-6 mb-xl-9">
        <div className="card-header py-4">
          <p className="card-title align-items-start flex-column">
            <span className="fw-bold h4" style={{ maxWidth: "400px" }}>
              SPRINTS
            </span>
          </p>
          {(decryptRole === role.Manager || decryptRole === role.Admin || decryptRole === role.TeamLead) && (
            <div className="text-left pt-2">
              <button type="button" className="btn btn-primary" onClick={handleSprintAddClick}>
                <span className="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                  </svg>
                </span>
                Add Sprint
              </button>
            </div>
          )}
        </div>
        <div className="card-body py-2 mb-6" style={{ maxHeight: "350px", overflow: "auto" }}>
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5">
              <thead>
                <tr className="text-start text-dark fw-bold fs-7 text-uppercase gs-0">
                  <th className="min-w-80px ps-2">Name</th>
                  <th className="min-w-80px">Status</th>
                  <th className="min-w-80px">Start Date</th>
                  <th className="min-w-80px">End Date</th>
                  <th className="min-w-175px">Progress</th>
                  <th className="min-w-80px">Action</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 fw-semibold">
                {sprints?.map((item, index) => (
                  <tr key={item?.value}>
                    <td>
                      <strong className="text-gray-900">{item?.label}</strong>
                    </td>
                    <td>
                      <div className={item?.status === "Closed" ? "badge badge-light-danger fw-bold" : item?.status === "Active" ? "badge badge-light-success fw-bold" : "badge badge-light fw-bold"}>{item?.status}</div>
                    </td>
                    <td>
                      <div>{moment(item?.start_date, "YYYY-MM-DD").isValid() ? moment(item?.start_date, "YYYY-MM-DD").format("DD-MMM-YYYY") : ""}</div>
                    </td>
                    <td>
                      <div>{moment(item?.end_date, "YYYY-MM-DD").isValid() ? moment(item?.end_date, "YYYY-MM-DD").format("DD-MMM-YYYY") : ""}</div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center flex-column mt-3 w-100">
                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                          {/* {item?.total_tasks_count !== null && (
                            <>
                              <span className="fw-bold fs-6 text-gray-400">{(parseInt(item?.total_tasks_count) !== 0 ? 100 - (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : 0).toFixed(2)}%</span>
                              <span className="fw-semibold fs-6 text-dark">{(parseInt(item?.total_tasks_count) !== 0 ? (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : 0).toFixed(2)}%</span>
                            </>
                          )} */}
                          {item?.total_tasks_count !== null && (
                            <>
                              {parseInt(item?.total_tasks_count) !== 0 && parseInt(item?.unapproved_tasks) !== 0 ? (
                                <>
                                  <span className="fw-bold fs-6 text-gray-400">{`${item?.unapproved_tasks}/${item?.total_tasks_count} pending`}</span>
                                  <span className="fw-bold fs-6 text-gray-400">{(100 - (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100).toFixed(2)}%</span>
                                  {/* <span className="fw-semibold fs-6 text-dark">{((parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100).toFixed(2)}%</span> */}
                                </>
                              ) : parseInt(item?.total_tasks_count) !== 0 && parseInt(item?.unapproved_tasks) === 0 ? (
                                <div>Completed</div>
                              ) : (
                                <div>No Tasks</div>
                              )}
                            </>
                          )}
                        </div>
                        {item?.total_tasks_count !== null ? (
                          <div className={(parseInt(item?.total_tasks_count) !== 0 ? (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : "No Tasks") === 0 ? "h-8px mx-3 w-100 bg-success rounded" : "h-8px mx-3 w-100 bg-light-warning rounded"}>
                            <div className={(parseInt(item?.total_tasks_count) !== 0 ? (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : "No Tasks") === 0 ? "bg-success rounded h-8px" : "bg-warning rounded h-8px"} role="progressbar" style={(parseInt(item?.total_tasks_count) !== 0 ? (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : 0) === 0 ? { width: "100%", ariaValuenow: "50", ariaValuemin: "0", ariaValuemax: "100" } : { width: `${parseInt(item?.total_tasks_count) !== 0 ? 100 - (parseInt(item?.unapproved_tasks) / parseInt(item?.total_tasks_count)) * 100 : 0}%`, ariaValuenow: "50", ariaValuemin: "0", ariaValuemax: "100" }}></div>
                          </div>
                        ) : (
                          <div>---</div>
                        )}
                      </div>
                    </td>
                    <td>
                      {item?.status === "Closed" && (
                        <span
                          style={{ fontSize: "10px !important" }}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedSprint(item?.value);
                          }}
                        >
                          <i className="far fa-eye ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                        </span>
                      )}
                      {item?.status === "Active" && (
                        <>
                          {(decryptRole === role.Manager || decryptRole === role.Admin || decryptRole === role.TeamLead) && (
                            <>
                              <span style={{ fontSize: "10px !important" }} onClick={() => handleSprintStatus(item?.value, "stop", item?.unapproved_tasks)}>
                                <i className="fas fa-stop ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                              </span>{" "}
                              |
                            </>
                          )}
                          <span
                            style={{ fontSize: "10px !important" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedSprint(item?.value);
                            }}
                          >
                            <i className="far fa-eye ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                          </span>
                          {(decryptRole === role.Manager || decryptRole === role.Admin || decryptRole === role.TeamLead) && (
                            <>
                              {" "}
                              |
                              <span style={{ fontSize: "10px !important" }} onClick={(e) => handleSprintUpdateClick(e, item)}>
                                <i className="fas fa-pen ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                              </span>
                            </>
                          )}
                        </>
                      )}
                      {item?.status === "Not started" && (
                        <>
                          {(decryptRole === role.Manager || decryptRole === role.Admin || decryptRole === role.TeamLead) && (
                            <>
                              <span style={{ fontSize: "10px !important" }} onClick={() => handleSprintStatus(item?.value, "start")}>
                                <i className="fas fa-play ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                              </span>{" "}
                              |
                            </>
                          )}
                          <span
                            style={{ fontSize: "10px !important" }}
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedSprint(item?.value);
                            }}
                          >
                            <i className="far fa-eye ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                          </span>
                          {(decryptRole === role.Manager || decryptRole === role.Admin || decryptRole === role.TeamLead) && (
                            <>
                              {" "}
                              |
                              <span style={{ fontSize: "10px !important" }} onClick={(e) => handleSprintUpdateClick(e, item)}>
                                <i className="fas fa-pen ms-2" style={{ fontSize: "1.10rem", color: "#e65100", cursor: "pointer" }} />
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {(sprints ?? []).length === 0 && <div className="my-3">No Records Found ...</div>}
          </div>
        </div>
      </div>
      {showMainModalBox && <SprintDetailsModal showMainModalBox={showMainModalBox} closeTaskModal={closeTaskModal} setSuccess={setSuccess} success={success} modalValues={modalValues} />}
      {decryptRole === role.Admin && (
        <div className="col-6">
          <div className="card card-flush mb-6 mb-xl-9">
            <div className="card-header py-4">
              <p className="card-title align-items-start flex-column">
                <span className="fw-bold h4" style={{ maxWidth: "400px" }}>
                  Rework
                </span>
              </p>
            </div>
            <div className="card-body py-2 mb-6" style={{ overflow: "auto" }}>
              {loadingRework ? ( // Check if data is still loading
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span> {/* Accessible text for screen readers */}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <h2 style={{ display: "inline-block", padding: "0 10px" }}>{reworkCount?.totalBugCountForDev}</h2>
                        <h6 className="mb-0 text-gray-400">QA Fail</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <h2 style={{ display: "inline-block", padding: "0 10px" }}>{reworkCount?.totalBugCountForTester}</h2>
                        <h6 className="mb-0 text-gray-400">Client Review Fail</h6>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

    </div>
  );
};
export default SprintManagement;
