// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-daterange-picker__wrapper{
    border-radius: 7px;
    border-color: lightgray;
    padding: 5px;
}

.react-daterange-picker__calendar-button{
    display:none;
}

.react-daterange-picker__clear-button{
    transform: rotateY(45deg);
}
.css-3iigni-container{

    border-radius: 7px;
}
.react-daterange-picker
{
    width:100%;
}
.react-daterange-picker__inputGroup {
         min-width: 0;
        height: 100%;
         flex-grow: 0; 
        padding: 0 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Coder/coder.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;AACA;;IAEI,kBAAkB;AACtB;AACA;;IAEI,UAAU;AACd;AACA;SACS,YAAY;QACb,YAAY;SACX,YAAY;QACb,cAAc;AACtB","sourcesContent":[".react-daterange-picker__wrapper{\n    border-radius: 7px;\n    border-color: lightgray;\n    padding: 5px;\n}\n\n.react-daterange-picker__calendar-button{\n    display:none;\n}\n\n.react-daterange-picker__clear-button{\n    transform: rotateY(45deg);\n}\n.css-3iigni-container{\n\n    border-radius: 7px;\n}\n.react-daterange-picker\n{\n    width:100%;\n}\n.react-daterange-picker__inputGroup {\n         min-width: 0;\n        height: 100%;\n         flex-grow: 0; \n        padding: 0 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
